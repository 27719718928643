import React, { Component } from 'react';
import { Alert } from 'reactstrap';

export default class NotificationBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.hasNotification,
            title: null,
            message: null,
            closeClick: false
        };
        this.dismissError = this.dismissError.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.hasNotification !== state.show) {
            if (state.closeClick) {
                return { closeClick: false }
            }
            return {
                show: props.hasNotification
            };
        }
        return null;
    }

    dismissError() {
        this.setState({ show: false, closeClick: true });
        this.props.closeEvent();
    }

    render() {
        const { show } = this.state;
        const { title, message, notificationColor, dismissible } = this.props;
        return (
            <>
                <Alert color={notificationColor} isOpen={show} toggle={dismissible ? this.dismissError : false} fade={false}>
                    <h4 className="alert-heading">{title}</h4>
                    <p>
                        {message}
                    </p>
                </Alert>
            </>
        );
    }
}