import React, { Component } from 'react';
import Notification from '../notification/Index';
import { constants } from '../shared/constants';
import LoadSpinner from '../shared/spinner';
import MassUpdate from './massUpdate';
import nopicture from '../images/nopicture.png';

export default class SpecificationListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasNotification: false,
            notificationInfo: {},
            entityList: this.props.entityList,
            specificationList: null,
            listingId: this.props.listingId,
            loadingSpecListing: true,
            showMassUpdateModal: false,
            massUpdateInfo: null,
            previouslySeletedValues: []
        }
        this.displayErrorBar = this.displayErrorBar.bind(this);
        this.specificationLinkTypeIds = "";
        this.templateLanguage = window.$templateContext.templateLanguage;
    }

    componentDidMount() {
        if (this.props.entityList !== null) {
            this.loadSpecifications();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.listingId !== this.props.listingId) {
            this.loadSpecifications();
        }
    }

    render() {
        var contentClass = this.props.isOpen ? "content open" : "content";
        return (
            <>
                <div className={contentClass} style={{ left: this.props.newLeft }}>
                    <div className="spec-listing-box">
                        <header className="spec-box-header">
                            <h2 className="spec-box-header-title">
                                <span className="spec-box-header-text">Specifications</span>
                            </h2>
                        </header>

                        <div className="spec-group-box-content">
                            <Notification hasNotification={this.state.hasNotification} notificationInfo={this.state.notificationInfo} closeEvent={this.closeEvent.bind(this)}/>
                            {this.props.entityList !== null ? this.state.loadingSpecListing
                                ? <LoadSpinner />
                                : this.renderListing(this.state.specificationList) : null}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderListing(specificationList) {
        if (specificationList === null) return;

        var specificationGroupings = [];
        specificationList.forEach(function(spec) {
            var specificationEntityId = spec.outbound[0].entityId;
            var specificationId = spec.outbound[0].fieldValues[0].value;
            var specificationName = spec.outbound[0].fieldValues[1].value;

            if (specificationGroupings.length === 0) {
                specificationGroupings.push({ specificationEntityId, specificationId, specificationName });
                return;
            }

            var retrievedGroup = specificationGroupings.find(t => t.specificationEntityId === specificationEntityId);
            if (!retrievedGroup) {
                specificationGroupings.push({ specificationEntityId, specificationId, specificationName });
            }
        });
       
        return (
            <>
                {
                    Object.keys(specificationGroupings).map((i) => {
                        var specificationName = typeof(specificationGroupings[i].specificationName) === "object" ? 
                            specificationGroupings[i].specificationName[this.templateLanguage] : specificationGroupings[i].specificationName
                        return (
                            <div key={specificationGroupings[i].specificationEntityId} className="spec-listing-box-content">
                                <header className="spec-box-header">
                                    <h2 className="spec-box-header-title truncate">
                                        <div className="spec-box-header-icon">
                                            <span className="fa fa-television"></span>
                                        </div>
                                        <span className="spec-box-header-text">{specificationGroupings[i].specificationId}</span> &nbsp;|&nbsp;
                                        <span className="spec-box-header-text">{specificationName}</span>
                                    </h2>
                                    <div className="spec-box-header-actions">
                                        <div className="action">
                                            <input type="checkbox" id={"chkAll_" + specificationGroupings[i].specificationEntityId} onChange={this.handleChangeSelectAll}  className="cursor-pointer"/>&nbsp;
                                            <label htmlFor={"chkAll_" + specificationGroupings[i].specificationEntityId} className="cursor-pointer"> Select All</label>
                                        </div>
                                        <div className="action cursor-pointer">
                                            <div onClick={this.handleMassUpdate.bind(this, specificationGroupings[i])}>
                                                <span className="fa fa-edit" > </span> Mass Update
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <div className="workarea-card-section__content children:mb:16 children:mr:16">
                                    { this.renderItems(specificationList, specificationGroupings[i].specificationEntityId) }
                                </div>
                            </div>
                        );
                    })
                }
                {
                    this.state.showMassUpdateModal && <MassUpdate massUpdateInfo={this.state.massUpdateInfo}
                        closeEvent={this.closeMassUpdateModal.bind(this)}>
                    </MassUpdate>
                }
            </>
        );
    }

    renderItems(filteredEntities, specificationEntityId) {
        return (
            <>
                {
                    Object.keys(filteredEntities).map((i) => {
                        if (filteredEntities[i].outbound.length === 0) return (null);
                        if (filteredEntities[i].outbound[0].entityId === specificationEntityId) {
                            var backgroundImage = filteredEntities[i].summary.resourceUrl ?? nopicture;
                            var completenessColor = this.completenessColor(filteredEntities[i].summary.completeness);
                            var entityIcon = filteredEntities[i].summary.entityTypeId === "Item" ? "entity-card__name-icon fa fa-barcode" : "entity-card__name-icon fa fa-tag";

                            return (
                                <div key={filteredEntities[i].entityId} className="workarea-card">
                                    <div className="entity-card card-wrap-large" onClick={this.handleClickEntityCard}>
                                        <div className="entity-card__checkbox">
                                            <input type="checkbox" className={ `entity-card__checkbox-icon ${specificationEntityId}` } 
                                                id={`chkAll_${specificationEntityId}_${filteredEntities[i].entityId}`} />
                                        </div>
                                        <div className="entity-card__content">
                                            <div className="entity-card__image-wrapper">
                                                <div className="card-picture-frame-large">
                                                    <div className="entity-card__image entity-card__image--phase0" 
                                                        style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "contain", backgroundRepeat: "no-repeat",
                                                        backgroundPosition: "center center", opacity: "1"
                                                        }}>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="entity-card__text-wrapper">
                                                <div className="entity-card__name" title={filteredEntities[i].summary.displayName}>
                                                    <span className={entityIcon}></span>
                                                    <span className="truncate">
                                                    {filteredEntities[i].summary.displayName}
                                                    </span>
                                                </div>
                                                <div className="entity-card__description" title={filteredEntities[i].summary.displayDescription}>
                                                    {filteredEntities[i].summary.displayDescription}
                                                </div>
                                            </div>
                                            <div className="entity-card__icon completeness-small">
                                                <div className="pie-wrapper">
                                                    <span className="csspie" data-value={filteredEntities[i].summary.completeness} style={{ "--completeness-criteria-color": `${completenessColor}`}} title={filteredEntities[i].summary.displayName}></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        return (null);
                    })
                }
            </>
        );
    }

    loadSpecifications() {
        if (this.props.entityList.count === 0) {
            this.setError();
            return;
        }

        this.getSpecificationLinkTypeIds().then( x => {
            this.getSpecifications().catch(error => {
                this.displayErrorModal("Error", error.message);
            });
        }).catch(error => {
            this.displayErrorModal("Error", error.message);
        });
    }

    async getSpecificationLinkTypeIds() {
        this.setState({ loadingSpecListing: true, hasNotification: false});

        if (this.specificationLinkTypeIds !== "") return;

        var requestOptions = this.requestOptions(null, "model/entitytypes", "GET");
        var connectorId = window.localStorage.getItem('connector_id');
        const response = await fetch("adapter/" + connectorId, requestOptions)
        if (!response.ok) {
            const message = `An error has occured when fetching specification link type ids. \n Status: ${response.status}`;
            throw new Error(message);
        }
        const data = await response.json();
        var specification = data.find(function (entityType) {
            return entityType.id === "Specification";
        });
        this.specificationLinkTypeIds = specification.inboundLinkTypes.join(",");
    }

    async getSpecifications() {
        if(this.props.entityList.count > constants.apiMaxEntitiesLimit) {
            this.displayErrorModal("Limitation", `Selected workarea has returned more than ${constants.apiMaxEntitiesLimit} entities. Please refine your workarea.`);
            this.setState({ loadingSpecListing: false, specificationList: null});
            return;
        }

        var jsonData = JSON.stringify({
            "entityIds": this.props.entityList.entityIds,
            "objects": "EntitySummary",
            "outbound": {
                "linkTypeIds": this.specificationLinkTypeIds,
                "objects": "FieldValues"
            }
        });

        var requestOptions = this.requestOptions(jsonData, "entities:fetchdata", "POST");
        var connectorId = window.localStorage.getItem('connector_id');
        const response = await fetch("adapter/" + connectorId, requestOptions)
        if (!response.ok) {
            const message = `An error has occured when fetching specification listing. \n Status: ${response.status}`;
            throw new Error(message);
        }

        const data = await response.json();
        if (data === null)
        {
            this.setError();
            return;
        }

        var filteredEntities = data.filter(function(entity) {
            return entity.outbound.length !== 0
        });

        if (filteredEntities.length === 0) {
            this.setError();
            return;
        }

        this.setState({ specificationList: filteredEntities, loadingSpecListing: false, hasNotification: false });
    }

    setError() {
        this.displayErrorBar("There are no entities using Specification in this workarea/query.");
        this.setState({ loadingSpecListing: false, specificationList: null});
    }

    requestOptions(jsonData, apiFunction, method) {
        var connectorId = window.localStorage.getItem('connector_id');
        var token = window.localStorage.getItem('access_token');

        return {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token
            },
            body: JSON.stringify(
                {
                    ConnectorId: connectorId,
                    JsonData: jsonData,
                    Method: method,
                    ApiFunction: apiFunction,
                }
            )
        };
    }

    displayErrorBar(message) {
        var notificationInfo = {
            notificationType: constants.notificationType.notificationBar,
            message: message,
            notificationColor: constants.color.info,
            dismissible: true
        }

        this.setState({ hasNotification: true, notificationInfo });
    }

    displayErrorModal(title, message) {
        var notificationInfo = {
            notificationType: constants.notificationType.notificationModal,
            title: title,
            message: message
        }

        this.setState({ hasNotification: true, notificationInfo });
    }

    closeEvent() {
        this.setState({ hasNotification: false, notificationInfo: {} });
    }

    completenessColor(completeness) {
        if (completeness === 100) {
            return "#3ec300";
        }
        else if (completeness === 0) {
            return "#ff220c";
        }
        else {
            return "#ffba08";
        }
    }

    handleClickEntityCard(e) {
        var checkbox = window.$(e.currentTarget).parent().find('input:checkbox:first');

        // Switch checked state
        if(e.target.type !== "checkbox") {
            checkbox.prop('checked', !checkbox.is(':checked')); 
        }

        var parentId = checkbox[0].id.split('_')[1];
        if (checkbox.is(":checked")) {
            var isAllChecked = 0;

            window.$("." + parentId).each(function() {
                if (!this.checked)
                    isAllChecked = 1;
            });

            if (isAllChecked === 0) {
                window.$("#chkAll_" + parentId).prop("checked", true);
                window.$(e.currentTarget).addClass("entity-card--selected");
                return;
            }

            window.$(e.currentTarget).addClass("entity-card--selected");
        }
        else {
            window.$("#chkAll_" + parentId).prop("checked", false);
            window.$(e.currentTarget).removeClass("entity-card--selected");
        }
    }

    handleChangeSelectAll(e) {
        var chckAllId = e.target;
        var group = e.target.id.split('_')[1];
        if (chckAllId.checked) {
            window.$("." + group).each(function() {
                this.checked=true;
                window.$(this).closest('.entity-card').addClass("entity-card--selected");
            });
        } else {
            window.$("." + group).each(function() {
                this.checked=false;
                window.$(this).closest('.entity-card').removeClass("entity-card--selected");
            });
        }
    }

    handleMassUpdate(specGroupings) {
        var ids = [];
        window.$("." + specGroupings.specificationEntityId).each(function() {
            if (this.checked) {
                var id = this.id.split('_')[2];
                ids.push(id);
            }
        });

        var specGroup = { 
            specEntityId: specGroupings.specificationEntityId,
            specId: specGroupings.specificationId,
            specName: typeof(specGroupings.specificationName) === "object" ? specGroupings.specificationName[this.templateLanguage] : specGroupings.specificationName
        }

        var massUpdateInfo =  {
            show: true,
            selectedEntities: ids,
            specificationGroup: specGroup,
            selectedValues: this.state.previouslySeletedValues
        }
        this.setState({showMassUpdateModal: true, massUpdateInfo: massUpdateInfo});
    }

    closeMassUpdateModal(specEntityId, previouslySeletedValues) {
        var previousSelected = this.state.previouslySeletedValues;

        var retrievedGroup = previousSelected.find(t => t.specificationEntityId === specEntityId);
        if (!retrievedGroup) {
            previousSelected.push({ specEntityId, previouslySeletedValues});
        }

        this.setState({showMassUpdateModal: false, previouslySeletedValues: previousSelected});    
    }
}