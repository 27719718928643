import React, { Component } from "react";
import AuthService from "../service/AuthService";
import Specification from "../specification/specification";
import Notification from "../notification/Index";
import { constants } from '../shared/constants';
import HelloWorld from "../helloworld/helloworld";

window.$templateContext = null;

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            accessInfo: null,
            loading: true,
            templateContext: null,
            entityList: null,
            hasEntityIds: false,
            hasNotification: false,
            notificationInfo: {},
            success: false
        };
        this.authService = new AuthService();
        this.displayErrorBar = this.displayErrorBar.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params) {
            var token = this.props.match.params.token;
            this.getAccessInformation(token)
            .then((res) => {
                this.setState({ loading: false });
                this.props.app(this.authService.getServiceDescription());
            }).catch(error => {
                this.displayErrorBar(error.message);
            });
        } else {
            this.displayErrorBar("No token identified.");
        }
    }

    render() {
        return (
            <>
                <Notification hasNotification={this.state.hasNotification} notificationInfo={this.state.notificationInfo} closeEvent={this.closeEvent.bind(this)} />
                { this.state.success ? this.renderApp() : "" }
            </>
        );
    }

    renderApp(){
        const { entityList, hasEntityIds } = this.state;
        let serviceName = this.authService.getServiceName();

        switch(serviceName){
            case constants.serviceName.specificationMassUpdate : return(<Specification hasEntityIds={hasEntityIds} entityList={entityList} ></Specification>);
            case constants.serviceName.helloworld : return(<HelloWorld/>);
            default: return("");
        }
    }

    async getAccessInformation(token) {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token
            }
        };

        const response = await fetch("access", requestOptions);
        if (!response.ok) {
            const message = `An error has occured when getting access informations. \n Status: ${response.status}`;
            throw new Error(message);
        }

        const data = await response.json();
        this.authService.setSession(data);
        window.$templateContext = data.templateContext;
        
        if (data.templateContext.entityIds !== undefined && data.templateContext.entityIds !== null) {
            var entityIdsArray = data.templateContext.entityIds.split(',').map(Number);
            var filteredEntities = entityIdsArray.filter(function(item) {
                return !isNaN(item)
            });

            var entities = {
                count: filteredEntities.length,
                entityIds: filteredEntities

            }

            this.setState({ 
                hasEntityIds: true,
                entityList: entities 
            });
        }
        this.setState({ accessInfo: data, templateContext: data.templateContext, success: true });
    }

    displayErrorBar(message) {
        var notificationInfo = {
            notificationType: constants.notificationType.notificationBar,
            title: "Error",
            message: message,
            notificationColor: constants.color.danger,
            dismissible: false
        }

        this.setState({ hasNotification: true, notificationInfo });
    }

    closeEvent() {
        this.setState({ hasNotification: false, notificationInfo: {} });
    }
}
