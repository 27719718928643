import React, { Component } from 'react';

export default class HelloWorld extends Component {
    render(){
        return(
            <div>
               <h1>HELLO WORLD</h1>
            </div>
        );
    }
}