import React, { Component } from 'react';

export default class LoadSpinner extends Component {
    render() {
        return (
            <div className="overlay">
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="loader">
                        <i className="fa fa-circle-o-notch fa-spin" style={{ fontSize: "40px", color: "#1768b2" }}></i>
                    </div>
                </div>
            </div>
        );
    }
}