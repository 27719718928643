import React, { Component } from 'react';
import Notification from '../notification/Index';
import { constants } from '../shared/constants';
import $ from 'jquery';

export default class WorkArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workareas: null,
            loadingWorkAreas: true,
            hasNotification: false,
            notificationInfo: {}
        };
        this.displayErrorModal = this.displayErrorModal.bind(this);
    }

    componentDidMount() {
        this.getWorkAreaFolderTree().catch(error => {
            this.displayErrorModal(error.message);
        });
    }

    render() {
        var sideBarClass = this.props.isOpen ? "sidebar open" : "sidebar"
        return (
            <>
                <Notification hasNotification={this.state.hasNotification} notificationInfo={this.state.notificationInfo} closeEvent={this.closeEvent.bind(this)} />
                <div className={sideBarClass} style={{ width: this.props.newWidth }}>
                    <div className="spec-workarea-box">
                        <header className="spec-box-header">
                            <h2 className="spec-box-header-title">
                                <div className="spec-box-header-icon">
                                    <span className="fa fa-folder"></span>
                                </div>
                                <span className="spec-box-header-text">Work Areas</span>
                            </h2>
                            <span onClick={this.props.toggleWorkArea} className="sidebar-toggle">
                                <i className={this.props.isOpen ? "fa fa-chevron-left" : "fa fa-chevron-right"}></i>
                            </span>

                        </header>
                        <div onClick={this.props.toggleWorkArea} className="sidebar-header-vertical" style={{ height: '876px' }}>
                            <div className="sidebar-header-label-vertical" title="Expand">
                                <span>Work Areas</span>
                            </div>
                        </div>
                        <div className="spec-box-content spec-workarea-box-content">
                            {this.state.loadingWorkAreas
                                ? <p><em>Loading...</em></p>
                                : this.renderWorkAreaTree(this.state.workareas)}
                        </div>
                    </div>
                    <div onMouseDown={this.props.handleMouseDown} className="resizable-handle resizable-handle-e" style={{ zIndex: '90' }}></div>
                </div>
            </>
        );
    }

    renderWorkAreaTree(workAreas) {
        return (
            <div id="workAreaSelector">
                <ul>
                    { this.renderWorkArea(workAreas, "p_", this.hasTemplateContext ? window.$templateContext.userDisplayName : "Private", "topLevelPrivate") }
                    { this.renderWorkArea(workAreas, "s_", "Common Shared", "topLevelCommonShared") }
                </ul>
            </div>
        );
    }

    renderWorkArea(workAreas, prefix, rootNodeName, topLevelId) {
        return (
            <li key={topLevelId} id={topLevelId}>{rootNodeName}
                <ul>
                    {
                        Object.keys(workAreas).map((i) => {
                            if (workAreas[i].id.startsWith(prefix)) {
                                if (workAreas[i].folders !== undefined) {
                                    return (
                                        this.renderWorkAreaSubLevel(workAreas[i])
                                    );
                                }
                                else {
                                    let jsType = "{ \"type\": \"subFolder\" }";
                                    let value = workAreas[i].id;

                                    if (workAreas[i].isQuery === true) {
                                        jsType = "{ \"type\": \"queryFolder\" }";
                                    }
                                    return (
                                        <li key={workAreas[i].id} value={value} data-jstree={jsType}>{workAreas[i].name} </li>
                                    );
                                }
                            }
                            return (null);
                        })
                    }
                </ul>
            </li>
        );
    }

    renderWorkAreaSubLevel(subWorkAreas) {
        return (
            <li key={subWorkAreas.id} value={"subFolder"} data-jstree={"{ \"type\": \"subFolder\" }"}> {subWorkAreas.name}
                <ul>
                    {
                        Object.keys(subWorkAreas.folders).map((j) => {
                            if (subWorkAreas.folders[j].folders !== undefined) {
                                return(
                                    this.renderWorkAreaSubLevel(subWorkAreas.folders[j])
                                );
                            }
                            else {
                                let jsType = "{ \"type\": \"subFolder\" }";
                                let value = subWorkAreas.folders[j].id;
                                if (subWorkAreas.folders[j].isQuery === true) {
                                    jsType = "{ \"type\": \"queryFolder\" }";
                                }
                                return (
                                    <li key={subWorkAreas.folders[j].id} value={value} data-jstree={jsType}>{subWorkAreas.folders[j].name} </li>
                                );
                            }
                        })
                    }
                </ul>
            </li>
        );
    }

    async getWorkAreaFolderTree() {
        var connectorId = window.localStorage.getItem('connector_id');
        var token = window.localStorage.getItem('access_token');
        var request = {
            ConnectorId: connectorId,
            ParameterData: [{ Key: "forUsername", Value: window.$templateContext.userEmail }],
            Method: "Get",
            ApiFunction: "workareafoldertree",
        };

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token
            },
            body: JSON.stringify(request)
        };
        
        const response = await fetch("adapter/" + connectorId, requestOptions)
        if (!response.ok) {
            const message = `An error has occured when fetching work area informations. \n Status: ${response.status}`;
            throw new Error(message);
        }
        const data = await response.json();
        this.setState({ workareas: data, loadingWorkAreas: false });
        this.setupWorkAreaTreeView();
    }

    setupWorkAreaTreeView() {
        var $this = this;
        $(function() {
            window.$('#workAreaSelector').jstree({
                "types" : {
                    "default" : {
                      "icon" : "fa fa-folder"
                    },
                    "subFolder" : {
                      "icon" : "fa fa-folder-open"
                    },
                    "queryFolder" : {
                        "icon" : "fa fa-search-plus"
                    }
                },
                "plugins": ["wholerow", "types"]
            });
            window.$('#workAreaSelector').jstree("open_node", $('#topLevelPrivate'));
            window.$('#workAreaSelector').jstree("open_node", $('#topLevelCommonShared'));
            window.$('#workAreaSelector').bind("hover_node.jstree", function(e, data){
                $("#"+ data.node.id).attr("title",data.node.text);
            });
            window.$('#workAreaSelector').on("changed.jstree", function (e, data) {
                $this.onWorkAreaSelected(data.node.li_attr.value);
            });
        });
    }

    displayErrorModal(message) {
        var notificationInfo = {
            notificationType: constants.notificationType.notificationModal,
            title: "Error",
            message: message
        }
        this.setState({ hasNotification: true, notificationInfo });
    }

    hasTemplateContext() {
        return typeof window.$templateContext !== "undefined";
    }

    onWorkAreaSelected(folderId) {
        this.props.handleSelectedWorkArea(folderId);
    }

    closeEvent() {
        this.setState({ hasNotification: false, notificationInfo: {} });
    }
}