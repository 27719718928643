import $ from 'jquery';
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col,  FormGroup, Form, Input, Label, Button, Spinner } from 'reactstrap';
import Notification from '../notification/Index';
import { constants } from '../shared/constants';
import './massUpdate.css';

export default class MassUpdate extends Component {
    constructor(props) {
        super(props);
        this.closeMassUpdate = this.closeMassUpdate.bind(this);
        this.state ={
            hasNotification: false,
            notificationInfo: {},
            loadingSpecificationFields: false,
            specificationFieldTypes: null,  // list of specification field type ids retrieved from API
            hasRequestSent: false,
            currentSpecificationId: null,
            selectedFieldIds: null,
            selectedFieldValues: null,   // list of specification field ids with new values
            isFirstStep: true,
            hasRenderedValueFields: false,
            processingUpdate: false,
            fieldErrors: {}
        }
        this.selectedIds =[];
        this.templateLanguage = window.$templateContext.templateLanguage;
        this.displayNotificationBar = this.displayNotificationBar.bind(this);
        this.renderFieldsTreeView = this.renderFieldsTreeView.bind(this);
    }

    componentDidUpdate() {
        if(this.state.hasRequestSent === true){
            return;
        }

        if((this.state.currentSpecificationId === null) 
            || this.state.currentSpecificationId !== this.props.massUpdateInfo.specificationGroup["specEntityId"]){
            this.getSpecificationTemplateFields();
        }

        if (this.state.processingUpdate) {
            //this.handleStartUpdate();
            this.handleStartUpdate2();
        }
    }

    // TODO: I added this for the "Search functionalities" in Select options, but I have not used it to the code
    //       If you know a way how can we add a search func within Select, you are free to do so.
    componentDidMount() {
        const script = document.createElement("script");

        script.src = "https://cdn.jsdelivr.net/npm/select2@4.1.0-beta.1/dist/js/select2.min.js";
        script.async = true;
    
        document.body.appendChild(script);

        if((this.state.currentSpecificationId === null) 
            || this.state.currentSpecificationId !== this.props.massUpdateInfo.specificationGroup["specEntityId"]){
            this.getSpecificationTemplateFields();
        }

        const { massUpdateInfo } = this.props;

        if (massUpdateInfo.selectedValues === null) return;
        Object.keys(massUpdateInfo.selectedValues).map(x => {
            if (massUpdateInfo.selectedValues[x].specEntityId === massUpdateInfo.specificationGroup.specEntityId) 
            {
                return ( this.setState({
                    selectedFieldIds: massUpdateInfo.selectedValues[x].previouslySeletedValues.selectedFieldIds,
                    specificationFieldTypes:  massUpdateInfo.selectedValues[x].previouslySeletedValues.specificationFieldTypes,
                    selectedFieldValues: massUpdateInfo.selectedValues[x].previouslySeletedValues.selectedFieldValues
                }))
            }
            return (null);
        });
        
    }

    render() {
        const { massUpdateInfo } = this.props;
        return (
            <>
                <Modal isOpen={massUpdateInfo.show} backdrop="static" keyboard={false} size="xl" centered={true}>
                    <ModalHeader cssModule={{ 'modal-title': 'modal-title massupdate-modal-title truncate' }}>
                        {
                            massUpdateInfo.specificationGroup !== null && <>
                                    <span className="spec-box-header-text">Mass Update &nbsp;</span>
                                    <span className="spec-box-header-text">{massUpdateInfo.specificationGroup["specId"]}</span> &nbsp;|&nbsp;
                                    <span className="spec-box-header-text">{massUpdateInfo.specificationGroup["specName"]}</span>
                            </>
                        }
                    </ModalHeader>
                    <ModalBody>
                        {
                            massUpdateInfo.selectedEntities === null || massUpdateInfo.selectedEntities.length === 0 ? <p className="pad-left-right-15"> No entity selected. </p> :
                                <>
                                    <div  id="mass-update-wizard " className="wizard sticky">  
                                        <div className="steps unselectable"> 
                                            <ul>
                                                <li onClick={this.handlePrevious.bind(this)}>
                                                    <span className= {`number${this.state.isFirstStep ? " current-step" : ""}`}>1</span> Fields
                                                </li>
                                                <li onClick={this.handleNext.bind(this)}>
                                                    <span className= {`number${this.state.isFirstStep ? "" : " current-step"}`}>2</span> Values
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="modal-body-custom">
                                        <div className={`filter sticky ${this.state.isFirstStep ? "" : "hidden"}`}>
                                            <div className="filter-display">
                                                <div className="action">
                                                    <label>Filter</label> &nbsp; &nbsp;
                                                    <input type="text" id="filterFields"></input>
                                                </div>
                                                <div className="action">
                                                    <input type="checkbox" id="chkAllFields" onChange={this.handleChangeSelectAll.bind(this)} className="cursor-pointer" />&nbsp;
                                                    <label htmlFor="chkAllFields" className="cursor-pointer"> Select All</label>
                                                </div>
                                                <div className="action">
                                                    <input type="checkbox" id="chkShowFieldTypeId" onChange={this.handleShowFieldTypeId.bind(this)} className="cursor-pointer" />&nbsp;
                                                    <label htmlFor="chkShowFieldTypeId" className="cursor-pointer"> Show Specification Field Id</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="mass-update-body" className="mass-update-field-listing">
                                            <div className="notification" style={{ margin: '15px' }}>
                                                <Notification hasNotification={this.state.hasNotification} notificationInfo={this.state.notificationInfo} closeEvent={this.closeEvent.bind(this)} />
                                            </div>
                                            {this.state.loadingSpecificationFields
                                                ? <p className="pad-left-right-15"><em>Loading...</em></p>
                                                : this.state.isFirstStep
                                                    ? this.renderSpecificationFields()
                                                    : this.renderSpecificationValues()
                                            }
                                        </div>
                                    </div>
                                </>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <div className="footer">
                            <Button color="secondary" onClick={this.closeMassUpdate}>Cancel</Button> {' '}
                            <Button color="primary" className={`${this.state.isFirstStep ? "hidden" : "active-action"}`} onClick={this.handlePrevious.bind(this)}>Previous</Button>{' '}
                            <Button color="primary" className={`${this.state.isFirstStep ? "active-action" : "hidden"}`} onClick={this.handleNext.bind(this)}>Next</Button> {' '}
                            <Button color="primary" className={`${this.state.isFirstStep ? "hidden" : "active-action"}`} onClick={this.handleUpdate.bind(this)} id="updateBtn">
                                    <div className="loadingBtn">
                                        { this.state.processingUpdate ? <Spinner size="sm" className="margin-right-5"/> : null }
                                        { this.state.processingUpdate ? "Processing" : "Start Update" }
                                    </div>
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    renderSpecificationFields(){
        if (this.state.specificationFieldTypes === null) return (null);

        var supportedDatatypes = ["Boolean", "Integer", "CVL", "Double", "String"];
        var filteredFields = this.state.specificationFieldTypes.filter(function(fieldType) {
            return supportedDatatypes.includes(fieldType.dataType);
        });

        const groupBy = key => array =>
            array.reduce((objectsByKeyValue, obj) => {
                const value = obj[key];
                objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
                return objectsByKeyValue;
            }, {});

        const groupByCategory = groupBy('categoryId');
        var categorizedFields = groupByCategory(filteredFields);

        return (
            <div id="massUpdateSelector">
                <ul>
                    {this.renderCategories(categorizedFields)}
                </ul>
            </div>
        );
    }

    renderCategories(categorizedFields) {
        return (
            Object.keys(categorizedFields).map((i) => {
                return(
                    <li key={i} id={i}> {categorizedFields[i][0].categoryId}
                        <ul>
                        {
                            Object.keys(categorizedFields[i]).map((j) => {
                                var unit = categorizedFields[i][j].unit;
                                return(
                                    <li key={`${this.state.currentSpecificationId}_${categorizedFields[i][j].id}`} id={categorizedFields[i][j].id} value={categorizedFields[i][j].id}>
                                        <span className="spec-ids-toggle" style={{ display: 'none'}} >{categorizedFields[i][j].id} | </span>
                                        {categorizedFields[i][j].name[this.templateLanguage]} 
                                        {unit === "" ? "" : ` (${unit})` }
                                    </li>
                                )
                            })
                        }
                        </ul>
                    </li>
                )
            })
        );
    }

    renderSpecificationValues() {
        var selectedFieldIds = this.state.selectedFieldIds;
        var specificationFieldTypes = this.state.specificationFieldTypes;
        return(
            <Form id="sendBody">
                {
                    Object.keys(specificationFieldTypes).map((i) => {
                        var unit = specificationFieldTypes[i].unit;
                        if (!selectedFieldIds.includes(specificationFieldTypes[i].id)) return (null);
                        return (
                            <FormGroup row key={specificationFieldTypes[i].id}>
                                <Label sm={4}>{specificationFieldTypes[i].name[this.templateLanguage]} {unit === "" ? "" : ` (${unit})` }</Label>
                                <Col sm={7}>
                                    {this.renderSwitch(specificationFieldTypes[i])}
                                    <span className='text-danger'>{this.state.fieldErrors[specificationFieldTypes[i].id +"_value"]}</span>
                                </Col>
                            </FormGroup>
                        )
                    })
                }
            </Form>
        )
    }

    renderSwitch(specificationFieldTypes) {
        var fieldType = specificationFieldTypes.dataType;
        var fieldId = specificationFieldTypes.id + "_value";
        var cvlId = specificationFieldTypes.cvlId;
        var isMultiValue = specificationFieldTypes.isMultiValue;
        var isMandatory = specificationFieldTypes.isMandatory;

        var fieldValues = this.state.selectedFieldValues;
        var fieldValue;
        if (fieldValues !== null) {
            $.each(fieldValues["specificationValues"], function(i) {
                var statefieldId = `${fieldValues["specificationValues"][i].specificationFieldTypeId}_value`;
                if (fieldId === statefieldId)
                {
                    fieldValue = fieldValues["specificationValues"][i].value;
                    return false;
                }
            });
        }

        switch(fieldType) {
          case 'String':
                return (
                    <Input type="text" id={fieldId} defaultValue={fieldValue ?? null} className={`input-field ${isMandatory ? "required" : ""}`} 
                        bsSize="sm" onKeyUp={this.handleChange.bind(this)} />
                );
          case 'Double':
                return (
                    <Input type="text" id={fieldId} defaultValue={fieldValue ?? null} className={`input-field double ${isMandatory ? "required" : ""}`} 
                        bsSize="sm" regex="^[-+]?[0-9]*\.?[0-9]+$" onKeyUp={this.handleChange.bind(this)} />
                ); 
          case 'Integer':
                return (
                    <Input type="text" id={fieldId} defaultValue={fieldValue ?? null} className={`input-field integer ${isMandatory ? "required": ""}`} 
                        bsSize="sm" regex="^([+-]?[0-9]*)$" onKeyUp={this.handleChange.bind(this)}/>
                );
          case 'Boolean':
            return (this.renderBooleanElement(fieldId, fieldValue, isMandatory));
          case 'CVL':
            return (this.renderSelectElements(fieldId, cvlId, isMultiValue, fieldValue, isMandatory));
          default:
            return null;
        }
    }

    renderBooleanElement(fieldId, value, isMandatory){
        return(
            <div id={fieldId} className="boolean">
                <FormGroup check inline>
                    <Label check > <Input type="radio" id={`${fieldId}_notSet`} name={fieldId} value="null" defaultChecked={value === null || value === undefined} 
                         onChange={this.handleChange.bind(this)} className={isMandatory ? "required": ""} />{' '}(not set)
                    </Label>
                </FormGroup>
                <FormGroup check inline>
                    <Label check> <Input type="radio" id={`${fieldId}_true`} name={fieldId} value="true" defaultChecked={value === true} 
                         onChange={this.handleChange.bind(this)} className={isMandatory ? "required": ""} />{' '}True
                    </Label>
                </FormGroup>
                <FormGroup check inline>
                    <Label check > <Input type="radio" id={`${fieldId}_false`} name={fieldId} value="false" defaultChecked={value === false} 
                         onChange={this.handleChange.bind(this)} className={isMandatory ? "required": ""} />{' '}False
                    </Label>
                </FormGroup>
            </div>
        );
    }

    renderSelectElements(fieldId, cvlId, isMultiValue, value, isMandatory){
        var cvlValues=[];
        if(window.$cvlValues !== undefined){
            var match =  window.$cvlValues.filter(values => values.cvlId === cvlId);
            if(match.length > 0){
                cvlValues = match[0].values;
            }
        }

        return(
            <Input type="select" id={fieldId} bsSize="sm" defaultValue={value === null || value === undefined ? "null" : value} className={isMandatory ? "required": ""} 
                onChange={this.handleChange.bind(this)}>
                <option id="null" value="null">(not set)</option>
                {
                    Object.keys(cvlValues).map((i) => {
                        return (
                            <option key={`${cvlValues[i].key}"_"${i}`} id={`${cvlValues[i].key}"_"${i}`} value={cvlValues[i].key}>{cvlValues[i].key} | {cvlValues[i].value[this.templateLanguage]}</option>
                        )
                    })
                }
            </Input>
        )
    }

    async getCvlValues(cvlId) {
        var retrievedCvl = window.$cvlValues.find(cvl=> cvl.cvlId === cvlId);
        if (!retrievedCvl) {
            var requestOptions = this.createAPIRequestOptions("", "GET", "/model/cvls/" + cvlId + "/values");
            var connectorId = window.localStorage.getItem('connector_id');

            const response = await fetch("adapter/" + connectorId, requestOptions)
            if (!response.ok) {
                const message = `An error has occured when fetching CVL Values for CVLID  ` + cvlId + `. \n Status: ${response.status}`;
                throw new Error(message);
            }

            const data = await response.json();

            retrievedCvl = { "cvlId": cvlId, "values": data };
            window.$cvlValues.push(retrievedCvl);
            this.renderSelectOptions(cvlId, retrievedCvl.values);
        }
    }

    renderSelectOptions(cvlId, data){
        var selectId = cvlId+"_value";

        if(data === undefined)
            data = window.$cvlValues.find(cvl=> cvl.cvlId === cvlId);

        if(data === undefined)
            return;

        Object.keys(data).map((i) => {
            return(
                $('#'+selectId).append( '<option value="'+data[i].key+'">'+data[i].key +' | '+data[i].value[this.templateLanguage]+'</option>' )
            );
        });
    }

    async getSpecificationTemplateFields() {
        const { massUpdateInfo } = this.props;
        if(massUpdateInfo.selectedEntities === null || massUpdateInfo.selectedEntities.length === 0)
            return;
        
        this.setState({ loadingSpecificationFields: true, hasRequestSent: true, currentSpecificationId: massUpdateInfo.specificationGroup["specEntityId"]});
        
        var requestOptions = this.createAPIRequestOptions("", "GET", "model/specificationtemplates/"+ massUpdateInfo.specificationGroup["specEntityId"]+"/fieldtypes")
        var connectorId = window.localStorage.getItem('connector_id');

        const response = await fetch("adapter/" + connectorId, requestOptions)
        if (!response.ok) {
            const message = `An error has occured when fetching FieldTypes for Specification `+ massUpdateInfo.specificationGroup["specEntityId"]+`. \n Status: ${response.status}`;
            throw new Error(message);
        }

        const data = await response.json();
        this.setState({ specificationFieldTypes: data, loadingSpecificationFields: false, hasNotification: false, hasRequestSent: false });
        this.renderFieldsTreeView();
    }
    
    renderFieldsTreeView(){
        var $this = this;
        $(function() {
            window.$('#massUpdateSelector').jstree({
                "core": {
                    "themes":{
                        "icons":false
                    }
                },
                "checkbox": {
                    "keep_selected_style" : false,
                    "tie_selection": false
                },
                "search": {
                    'case_insensitive': true,
                    'show_only_matches' : true
                },
                "plugins" : [ "checkbox", "search", "wholerow" ]
            });
            
            //environment differs on how they name the general category name so put the two instead
            window.$('#massUpdateSelector').jstree("open_node", $('#General'));
            window.$('#massUpdateSelector').jstree("open_node", $('#general'));

            window.$('#massUpdateSelector').jstree("hide_dots");
            window.$('#massUpdateSelector').bind("hover_node.jstree", function(e, data){
                $("#"+ data.node.id).attr("title", data.node.id);
            });
            window.$('#massUpdateSelector').on('search.jstree', function (nodes, str, res) {
                $this.showFieldTypeId($("#chkShowFieldTypeId").is(":checked"));
                if (str.nodes.length===0) {
                    window.$('#massUpdateSelector').jstree(true).hide_all();
                }
            });
            window.$('#filterFields').keyup(function(){
                window.$('#massUpdateSelector').jstree(true).show_all();
                window.$('#massUpdateSelector').jstree('search', $(this).val());
                $this.showFieldTypeId($("#chkShowFieldTypeId").is(":checked"));
            });

            $.each($this.state.selectedFieldIds, function(idx) {
                window.$('#massUpdateSelector').jstree('check_node', $this.state.selectedFieldIds[idx]);
            });

            $this.showFieldTypeId($("#chkShowFieldTypeId").is(":checked"));
        });      
    }

    handleChangeSelectAll(e) {
        if(e.target.checked) {
            window.$('#massUpdateSelector').jstree("check_all");
        }
        else {
            window.$('#massUpdateSelector').jstree("uncheck_all");
        }

        this.showFieldTypeId($("#chkShowFieldTypeId").is(":checked"));
    }

    handleShowFieldTypeId(e) {
        this.showFieldTypeId(e.target.checked);
    }

    showFieldTypeId(checked) {
        var specIds = $('.spec-ids-toggle');
        if (specIds.length > 0) {
            specIds.map(idx => { 
                var element = $(specIds[idx]);
                if(checked) {
                    return $(element).css("display", "")
                }
                else {
                    return $(element).css("display", "none")
                }
            }) 
        }
    }

    handleNext() {
        this.closeEvent();
        var specificationFieldTypeIds = [];
        var selectedElms = window.$('#massUpdateSelector').jstree("get_checked", true);
        $.each(selectedElms, function() {
            if(this.parent !== "#")
            specificationFieldTypeIds.push(this.id);
        });

        if(specificationFieldTypeIds.length ===0)
            return; 

        this.cvlIdsToBeRequestedToAPI(specificationFieldTypeIds);
        this.selectedIds = specificationFieldTypeIds;
        this.setState({selectedFieldIds: this.selectedIds, isFirstStep: false});
    }

    handlePrevious() {
        this.closeEvent();
        if(this.state.isFirstStep)
            return;

        var selectedJsonObject = this.retrieveFieldsWithValues();
        var selectedSpecificationValues = {"specificationId": this.props.massUpdateInfo.specificationGroup["specEntityId"], "specificationValues": selectedJsonObject};
        this.setState({isFirstStep: true, selectedFieldValues: selectedSpecificationValues});
        this.renderFieldsTreeView();
    }

    handleUpdate() {
        this.closeEvent();
        var validation = this.validateData();
        if (validation.hasValidationError) {
            return;
        }
        this.setState({ processingUpdate: true });
    }

    // Added fix for concurrent limitation on inriver RESTAPI
    handleStartUpdate2(){
        const { massUpdateInfo } = this.props;
        var selectedJsonObject = this.retrieveFieldsWithValues();
        var selectedEntityIds = massUpdateInfo.selectedEntities;
        var hasError = false;
        this.disableButtons(true);
       
        var self = this;

        var batchSend = setInterval(function(){
            if(selectedEntityIds.length <= 0){
                var selectedSpecificationValues = {
                    "specificationId": massUpdateInfo.specificationGroup["specEntityId"], 
                    "specificationValues": selectedJsonObject
                };

                if (hasError) {
                    setTimeout(self.setState({ processingUpdate: false, selectedFieldValues:  selectedSpecificationValues }), 2000);
                   
                    self.displayNotificationBar("Error has occured.", constants.color.danger);
                    self.disableButtons(false);
                }
                else {
                    self.setState({ processingUpdate: false, selectedFieldValues:  selectedSpecificationValues });
                    window.alertify.success('Updated successfully.');
                    self.closeMassUpdate();
                }

                clearInterval(batchSend)
            }

            var batchEntityIds = selectedEntityIds.splice(0, 8);

            Promise.all(batchEntityIds.map((id, i) => {
                return new Promise(resolve => {
                    resolve(self.startSpecificationMassUpdate(id ,selectedJsonObject), 1000);
                }).catch(err => {
                    hasError = true;
                });
            })).then(results =>  {

            }).catch(error => {
                self.displayNotificationBar("Error has occured.", constants.color.danger);
                self.disableButtons(false);
            });
        }, 1500);
    }


    handleStartUpdate() {
        const { massUpdateInfo } = this.props;
        var selectedJsonObject = this.retrieveFieldsWithValues();
        var selectedEntityIds = massUpdateInfo.selectedEntities;
        var hasError = false;
        this.disableButtons(true);

        //NOTE: inriver has 10 concurrent request limitation. Current using setTimeout to throttle
        var cntr = 0;
        Promise.all(selectedEntityIds.map((id, i) => {
            cntr += 1;

            return new Promise(resolve => {
                if (cntr === 5) {
                    setTimeout(resolve(this.startSpecificationMassUpdate(id ,selectedJsonObject)), 5000);
                    cntr = 0;
                }
                else {
                    resolve(this.startSpecificationMassUpdate(id ,selectedJsonObject));
                }
            }).catch(err => {
                hasError = true;
            });
        })).then(results =>  {
            var selectedSpecificationValues = {
                "specificationId": massUpdateInfo.specificationGroup["specEntityId"], 
                "specificationValues": selectedJsonObject
            };
            
            if (hasError) {
                setTimeout(this.setState({ processingUpdate: false, selectedFieldValues:  selectedSpecificationValues }), 2000);
                this.displayNotificationBar("Error has occured.", constants.color.danger);
                this.disableButtons(false);
            }
            else {
                this.setState({ processingUpdate: false, selectedFieldValues:  selectedSpecificationValues });
                window.alertify.success('Updated successfully.');
                this.closeMassUpdate();
                
            }
        }).catch(error => {
            this.displayNotificationBar("Error has occured.", constants.color.danger);
            this.disableButtons(false);
        });
    }

    cvlIdsToBeRequestedToAPI(specificationFieldTypeIds){
        var cvlIds =[];
        var specificationFieldTypes = this.state.specificationFieldTypes;
        Object.keys(specificationFieldTypes).map((i) => {
            if(specificationFieldTypeIds.includes(specificationFieldTypes[i].id) && specificationFieldTypes[i].cvlId !== null){
                cvlIds.push({
                    "id":specificationFieldTypes[i].id,
                    "cvlId": specificationFieldTypes[i].cvlId});
                return (
                    this.getCvlValues(specificationFieldTypes[i].cvlId)
                );
            }
            return (null);
        });
    }

    retrieveFieldsWithValues(){
        var selectedIds = this.state.selectedFieldIds;
        var selectedJsonObject =[];
        Object.keys(selectedIds).map((i) => {
            var elementId = selectedIds[i]+"_value";
            var element = window.document.getElementById(elementId);

            if (element === null) {
                element = window.document.getElementsByName(elementId);
            }

            var value = null;
            if(element.tagName === "INPUT" && element.type === "text"){
               if (element.value === "") {
                   value = null;
               }
               else {
                    if ($(element).hasClass("integer") || $(element).hasClass("double"))
                    {
                        value = Number(element.value);
                    }
                    else {
                        value = element.value;
                    }
               }
            }
            else if(element.tagName === "DIV" && $(element).hasClass("boolean")){ //radio buttons
                value = $("input[name="+elementId+"]:checked").val();
                if(value === "null"){
                    value = null;
                }else{
                    value = value === "true";
                }
            }
            else if(element.tagName === "SELECT"){
                value = element.options[element.selectedIndex].value;
                if(value === "null") value = null;
            }
            
            selectedJsonObject.push({
                "specificationFieldTypeId": selectedIds[i],
                "value": value
            });

            return selectedJsonObject;
        });

        return selectedJsonObject;
    }

    async startSpecificationMassUpdate(entityId, jsonFieldValues){
        var requestOptions = this.createAPIRequestOptions(jsonFieldValues, "PUT", "entities/"+entityId+"/specificationvalues");
        var connectorId = window.localStorage.getItem('connector_id');
        const response = await fetch("adapter/" + connectorId, requestOptions)
        if (!response.ok) {
            const message = `An error occured while saving `+entityId+`. \n Status: ${response.status}`;
            throw new Error(message);
        }
    }

    createAPIRequestOptions(data, method, apiFunction){
        var connectorId = window.localStorage.getItem('connector_id');
        var token = window.localStorage.getItem('access_token');
        var jsonData = JSON.stringify(data);

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token,
                "Retry-After": "3600"
            },
            body: JSON.stringify(
                {
                    ConnectorId: connectorId,
                    Method: method,
                    JsonData: jsonData,
                    ApiFunction: apiFunction,
                }
            )
        };

        return requestOptions;
    }

    closeMassUpdate() {
        var specEntityId = this.props.massUpdateInfo.specificationGroup.specEntityId;
        var previouslySeletedValues = {
            selectedFieldIds: this.state.selectedFieldIds,
            specificationFieldTypes: this.state.specificationFieldTypes,
            selectedFieldValues: this.state.selectedFieldValues
        }
        this.props.closeEvent(specEntityId, previouslySeletedValues);
    }

    displayNotificationBar(message, color) {
        var notificationInfo = {
            notificationType: constants.notificationType.notificationBar,
            message: message,
            notificationColor: color,
            dismissible: true
        }
        this.setState({ hasNotification: true, notificationInfo });
    }

    closeEvent() {
        this.setState({ hasNotification: false, notificationInfo: {} });
    }

    disableButtons(disable) {
        if (disable) {
            $(`.btn`).attr("disabled", true)
        }
        else {
            $(`.btn`).attr("disabled", false)
        }
    }

    handleChange(e) {
        let fieldErrors = this.state.fieldErrors;
        var element = $(e.target);
        fieldErrors = this.validatePerField(element, fieldErrors);

        var hasError = false;
        Object.keys(fieldErrors).map(id => {
            if(fieldErrors[id] !== '')  return hasError = true;
            return hasError;
        });

        $('#updateBtn').attr("disabled", hasError? true : false);
        this.setState({ fieldErrors });
    }

    validateData() {
        var inputs = $("#sendBody :input");
        let fieldErrors = this.state.fieldErrors;

        if (inputs.length > 0) {
            inputs.map(idx => {
                return (fieldErrors = this.validatePerField($(inputs[idx]), fieldErrors));
            });

            var hasError = false;
            Object.keys(fieldErrors).map(id => {
                if(fieldErrors[id] !== '') return hasError = true;
                return hasError;
            });

            $('#updateBtn').attr("disabled", hasError? true : false);
            this.setState({ fieldErrors });
        }
        return { hasValidationError: hasError };
    }

    validatePerField(element, fieldErrors) {
        var id = element.attr("id");
        var cnt = id.split("_");
        var value = element.val();

        if (cnt.length === 3) {
            var indexOf = id.lastIndexOf('_');
            id = id.substring(0, indexOf);
            value = $("input[name="+id+"]:checked").val();
        }

        var regExp = element.attr("regex");
        if (regExp !== undefined && value !== undefined && regExp.length > 0 && value.length > 0) {
            if (!value.match(regExp)) {
                if ($(element).hasClass("double")) {
                    fieldErrors[id] = 'input must be a decimal number';
                }
                else if ($(element).hasClass("integer")) {
                    fieldErrors[id] = 'input must be a number';
                }
                else {
                    fieldErrors[id] = 'input is invalid';
                }
            }
            else {
                fieldErrors[id] = '';
            }
        }
        else {
            if ($(element).hasClass("required") && (value === undefined || value === "" || value === "null")) {
                fieldErrors[id] = 'field is mandatory';
            }
            else {
                fieldErrors[id] = '';
            }
        }

        return fieldErrors;
    }
}