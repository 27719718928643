import React, { Component } from "react";

export class CloudApp extends Component {
    componentDidMount() {
        this.props.app("Avensia Cloud Apps");
    }
    
    render() {
        return (
            <div>
                This is cloud apps home...
            </div>
        )
    }
}