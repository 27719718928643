import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class NotificationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.hasNotification,
            title: null,
            message: null,
            closeClick: false
        };
        this.closeNotification = this.closeNotification.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.hasNotification !== state.show) {
            if (state.closeClick) {
                return { closeClick: false }
            }
            return {
                show: props.hasNotification
            };
        }
        return null;
    }

    closeNotification() {
        this.setState({ show: false, closeClick: true });
        this.props.closeEvent();
    }

    render() {
        const { show} = this.state;
        const { title, message } = this.props;
        return (
            <>
                <Modal isOpen={show} backdrop="static" keyboard={false}>
                    <ModalHeader toggle={this.closeNotification}>{title}</ModalHeader>
                    <ModalBody>
                        <div style={{ padding: '1rem'}}>
                            {message}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="danger" onClick={this.closeNotification}>Close</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}