export const constants = {
    notificationType: {
        notificationBar: "notificationBar",
        notificationModal: "notificationModal"
    },
    color: {
        primary: "primary",
        secondary: "secondary",
        success: "success",
        danger: "danger",
        warning: "warning",
        info: "info",
        light: "light",
        dark: "dark"
    },
    serviceName: {
        specificationMassUpdate: "inRiverSpecificationMassUpdate",
        helloworld: "helloworld"
    },
    apiMaxEntitiesLimit: 1000
}