import React, { Component } from 'react';
import WorkArea from "./workArea";
import SpecificationListing from "./specificationListing";
import './specification.css';

const minSidebarWidth = 400;
const maxSidebarWidth = 600;

window.$cvlValues = [];

export default class Specification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workAreaOpen: !this.props.hasEntityIds,
            newWidth: null,
            newLeft: null,
            entityList: this.props.entityList,
            listingId: 0
        };

        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
    }

    render() {
        const { workAreaOpen, entityList, listingId } = this.state;
        return(
            <div>
                <WorkArea isOpen={workAreaOpen} toggleWorkArea={this.handleViewWorkArea.bind(this)} 
                    handleMouseDown={this.handleMouseDown.bind(this)} handleSelectedWorkArea={this.handleSelectedWorkArea.bind(this)}
                    newWidth={ workAreaOpen ? this.state.newWidth : null}></WorkArea>
                <SpecificationListing isOpen={workAreaOpen} newLeft={workAreaOpen ? this.state.newLeft : null} entityList={entityList} listingId={listingId}></SpecificationListing>
            </div>
        );
    }


    handleViewWorkArea() {
        this.setState({ workAreaOpen: !this.state.workAreaOpen});
    }

    handleMouseDown(e)
    {
        window.addEventListener("mouseup", this.handleMouseUp);
        window.addEventListener("mousemove", this.handleMouseMove);
    }

    handleMouseUp()
    {
        window.removeEventListener("mouseup", this.handleMouseUp);
        window.removeEventListener("mousemove", this.handleMouseMove);
    }

    handleMouseMove(e)
    {
        var x = e.pageX + 2;
        if (x > minSidebarWidth && x < maxSidebarWidth)
        {
            this.setState({
                newLeft: e.pageX + 2,
                newWidth: e.pageX + 2
            });
        }
    }

    handleSelectedWorkArea(workAreaFolderId) {
        if (workAreaFolderId === undefined || workAreaFolderId === "subFolder") return;

        this.getEntityListByWorkAreaFolderId(workAreaFolderId);
    }

    async getEntityListByWorkAreaFolderId(workAreaFolderId) {
        if( this.state.listingId === workAreaFolderId) return;

        var connectorId = window.localStorage.getItem('connector_id');
        var token = window.localStorage.getItem('access_token');
        var request = {
            ConnectorId: connectorId,
            Method: "Get",
            ApiFunction: "workareafolder/" + workAreaFolderId + "/entitylist",
        };

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token
            },
            body: JSON.stringify(request)
        };
        const response = await fetch("adapter/" + connectorId, requestOptions)
        if (!response.ok) {
            const message = `An error has occured when fetching work area informations. \n Status: ${response.status}`;
            throw new Error(message);
        }
        const data = await response.json();
        this.setState({ entityList: data, listingId: workAreaFolderId });
    }
}