import React, { Component } from 'react';
import NotificationModal from '../notification/NotificationModal';
import NotificationBar from '../notification/NotificationBar';
import { constants } from '../shared/constants';

export default class Notification extends Component {
    renderNofiticationModal() {
        const { message, title } = this.props.notificationInfo;
        return(
            <NotificationModal hasNotification={this.props.hasNotification} message={message} title={title} closeEvent={this.props.closeEvent}/>
        );
    }

    renderNotificationBar() {
        const { message, title, notificationColor, dismissible } = this.props.notificationInfo;
        return(
            <NotificationBar hasNotification={this.props.hasNotification} message={message} title={title}
                notificationColor={notificationColor} dismissible={dismissible} closeEvent={this.props.closeEvent}/>
        );
    }

    render() {
        return(
            this.props.notificationInfo.notificationType === constants.notificationType.notificationBar ? 
                this.renderNotificationBar() : this.renderNofiticationModal()
        );
    }
}