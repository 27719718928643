import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { CloudApp } from './components/CloudApp';
import { constants } from './shared/constants';
import Notification from './notification/Index';
import registerServiceWorker from './registerServiceWorker';

import './custom.css'
import './csspie.css'

export default class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props);
        this.state = {
            appName: "",
            hasNotification: false,
            notificationInfo: {}
        }
    }

    appChange(appName) {
        this.setState({appName});
    }

    onUpdate(isUpdated) {
        console.log("onUpdate called " + isUpdated);
        if(isUpdated) {
            var notificationInfo = {
                notificationType: constants.notificationType.notificationModal,
                title: "Notification",
                message: "A new version of the app is available. Trying to refresh the page."
            }
            this.setState({ hasNotification: true, notificationInfo });
        }
    }

    reload() {
        console.log("reloading page");
        window.location.reload();
    }

    render() {
        return (
            <>
                <Notification hasNotification={this.state.hasNotification} notificationInfo={this.state.notificationInfo} closeEvent={this.reload.bind(this)} />
                <Layout appName={this.state.appName}>
                    <Route exact path='/' render={(props) => (<CloudApp {...props} app={this.appChange.bind(this)}></CloudApp>)} />
                    <Route exact path='/:token' render={(props) => (<Home {...props} app={this.appChange.bind(this)}></Home>)} />
                </Layout>
            </>
        );
    }

    componentDidMount() {
        registerServiceWorker(this.onUpdate.bind(this));
    }
}
