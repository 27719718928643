export default class AuthService {
    setSession(authResult) {
        let expiresAt = JSON.stringify((new Date(authResult.expiresIn).getTime()));
        window.localStorage.setItem('access_token', authResult.accessToken);
        window.localStorage.setItem('connector_id', authResult.connectorId);
        window.localStorage.setItem('connector_api_key', authResult.connectorApiKey);
        window.localStorage.setItem('expires_at', expiresAt);
        window.localStorage.setItem('template_context', JSON.stringify(authResult.templateContext));
        window.localStorage.setItem('service_name',  authResult.serviceName);
        window.localStorage.setItem('service_description',  authResult.serviceDescription);
      }
    
      isAuthenticated() {
        let expiresAt = JSON.parse(window.localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
      }
    
      getAccessToken() {
        const accessToken = window.localStorage.getItem('access_token');
        if (!accessToken) {
          throw new Error('No access token found');
        }
        return accessToken;
      }

      getServiceName() {
        return window.localStorage.getItem('service_name');
      }

      getServiceDescription() {
        return window.localStorage.getItem('service_description');
      }
}